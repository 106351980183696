import {
  Template,
  TitleDisplayMode,
  type PersoLists,
} from '@dce-front/sdk-hodor';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { contentRowTv } from '@dce-front/onewebapp-core-css';
import { hashObject } from '@dce-front/onewebapp-util-react';
import { Ratio, getRatio } from '@dce-front/onewebapp-utils';
import type { ContentRowHeader } from '@dce-front/sharedcomponent';
import type { JSX } from 'react';
import { memo, useMemo } from 'react';
import { StrateMode } from '../../../constants/strates';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import {
  getMediaDimensions,
  transformMediaDimensionsToPx,
} from '../../../helpers/mediaDimensions/getMediaDimensions';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import { useTranslation } from '../../../lang';
import { applicationResizeSelector } from '../../../store/slices/application-selectors';
import type { ContentRowLiveData } from '../../../templates/ContentRowLive/data/formatter';
import { StrateLoader } from '../../../templates/LandingV5/components/Strate/StrateLoader';
import type {
  ContentStrateV5,
  DisplayParameters,
} from '../../../templates/LandingV5/data/formatter';
import LoadableContentRowVirtual from '../../ContentRowVirtual/LoadableContentRowVirtual';
import Linker from '../../Linker/Linker';
import { useIsFrom } from '../../Page/useIsFrom';
import { ContentRowTemplateItemLiveTv } from '../ContentRowTemplateItem/ContentRowTemplateItemLiveTv/ContentRowTemplateItemLiveTv';
import { ContentRowTemplateItemPlaceholder } from '../ContentRowTemplateItem/ContentRowTemplateItemPlaceholder';
import { ContentRowTemplateItemStandard } from '../ContentRowTemplateItem/ContentRowTemplateItemStandard/ContentRowTemplateItemStandard';
import LoadableContentRow from '../LoadableContentRow';

export const ContentsDisplayMode = {
  Headline: 'headline',
  Top10: 'top',
} as const;

export type ContentsDisplayMode =
  (typeof ContentsDisplayMode)[keyof typeof ContentsDisplayMode];

export type ContentRowTemplateProps = {
  contents?: ContentStrateV5[] | ContentRowLiveData['channels'];
  displayParameters?: DisplayParameters;
  fetchNextContents?: (nbItemFetched?: number) => void;
  header?: ContentRowHeader;
  isFromCreativeMedia?: boolean;
  isImmersive?: boolean;
  isRemovableItem?: boolean;
  itemOffset?: number;
  listType?: PersoLists;
  onClickRemove?: (
    event: React.MouseEvent,
    contentID: string,
    listType: PersoLists,
  ) => void;
  strateMode?: StrateMode;
  focusManager?: FocusManager;
  trackingContext?: ApiV2Context;
};

/**
 * isLiveTvChannelContent :  check if content props is ContentRowLiveData['channels'][0] | ContentStrateV5
 */
const isLiveTvChannelContent = (
  content: ContentStrateV5 | ContentRowLiveData['channels'][0],
  strateMode: StrateMode,
): content is ContentRowLiveData['channels'][0] => {
  return strateMode === StrateMode.LiveTv;
};

function ContentRowTemplate({
  contents,
  displayParameters: {
    contentsDisplayMode = '',
    imageRatio = Ratio.Ratio169,
    imageSize = 'normal',
    titleDisplayMode = TitleDisplayMode.All,
    hidePlaceholders = false,
  } = {},
  fetchNextContents,
  header,
  isFromCreativeMedia = false,
  isImmersive,
  isRemovableItem,
  itemOffset,
  listType,
  onClickRemove,
  strateMode = StrateMode.Standard,
  focusManager,
  trackingContext,
}: ContentRowTemplateProps): JSX.Element {
  const { t } = useTranslation();
  const ratio = getRatio({ imageRatio });
  const showControls = !$_BUILD_RENDERMODE_CSR && Boolean(contents?.length);
  const isFromShowcase = useIsFrom(Template.Showcase);
  const isFromDetail = useIsFrom(Template.DetailPage);
  const resizeMode = useInvariantSelector(applicationResizeSelector);

  const labels = useMemo(
    () => ({
      next: t('HorizontalPaging.next'),
      previous: t('HorizontalPaging.previous'),
    }),
    [t],
  );

  const isTop10 = contentsDisplayMode === ContentsDisplayMode.Top10;

  const mediaDimensions = useMemo(() => {
    const numberOfItemOnScreen = (contentRowTv as any)[`${ratio}${imageSize}`]
      ?.wide;

    const mediaDimensionsWithRem = getMediaDimensions({
      ratio,
      titleDisplayMode:
        strateMode !== StrateMode.LiveTv ? titleDisplayMode : undefined,
      numberOfItemOnScreen,
      paddingLeftRight: isFromDetail ? 4 : 4.0625,
    });

    return transformMediaDimensionsToPx(
      {
        // add some additional width when isTop10
        widthMediaCard:
          mediaDimensionsWithRem.widthMediaCard + (isTop10 ? 5.813 : 0),
        heightMediaCard: mediaDimensionsWithRem.heightMediaCard,
      },
      resizeMode,
    );
  }, [
    ratio,
    imageSize,
    strateMode,
    titleDisplayMode,
    isFromDetail,
    isTop10,
    resizeMode,
  ]);

  const children = useMemo(() => {
    const tempTable: string[] = [];

    if (!contents?.length) {
      const placeholders = [...(Array(10) as null[])];

      return placeholders.map((_, index) => (
        <ContentRowTemplateItemPlaceholder
          key={hashObject({ index }, ['index'], tempTable).hash}
          ratio={ratio}
        />
      ));
    }

    return contents.map((content, index) => {
      if (isLiveTvChannelContent(content, strateMode)) {
        return (
          <ContentRowTemplateItemLiveTv
            key={hashObject({ ...content, index }, ['index'], tempTable).hash}
            channel={content}
            ratio={ratio}
            isHeadline={contentsDisplayMode === ContentsDisplayMode.Headline}
            imageSize={imageSize}
            titleDisplayMode={titleDisplayMode}
            trackingContext={trackingContext}
          />
        );
      }

      return (
        <ContentRowTemplateItemStandard
          key={
            hashObject({ ...content, index }, ['hash', 'index'], tempTable).hash
          }
          content={content}
          ratio={ratio}
          imageSize={imageSize}
          isBackgroundTransparent={hidePlaceholders}
          isRemovableItem={isRemovableItem}
          listType={listType}
          onClickRemove={onClickRemove}
          titleDisplayMode={titleDisplayMode}
          isTop10={isTop10}
        />
      );
    });
  }, [
    contentsDisplayMode,
    contents,
    imageSize,
    hidePlaceholders,
    isRemovableItem,
    listType,
    onClickRemove,
    ratio,
    strateMode,
    titleDisplayMode,
    isTop10,
    trackingContext,
  ]);

  if ($_BUILD_RENDERMODE_CSR) {
    return (
      <LoadableContentRowVirtual
        itemOffset={itemOffset}
        getNextContent={fetchNextContents}
        header={header}
        isFromDetail={isFromDetail}
        isFromShowcase={isFromShowcase}
        isFromCreativeMedia={isFromCreativeMedia}
        isOrderedList={isTop10}
        ratio={ratio}
        imageSize={imageSize}
        items={children}
        titleDisplayMode={titleDisplayMode}
        mediaDimensions={mediaDimensions}
        focusManager={focusManager}
        fallback={<StrateLoader />}
      />
    );
  }

  return (
    <LoadableContentRow
      defaultItemOffset={itemOffset}
      getNextContent={fetchNextContents}
      header={header}
      imageSize={imageSize}
      isFromDetail={isFromDetail}
      isFromImmersive={isImmersive}
      isFromShowcase={isFromShowcase}
      isFromCreativeMedia={isFromCreativeMedia}
      isOrderedList={isTop10}
      isTvDevice={false}
      labels={labels}
      Linker={Linker}
      ratio={ratio}
      scrollDuration={200}
      showControls={showControls}
      titleDisplayMode={titleDisplayMode}
    >
      {children}
    </LoadableContentRow>
  );
}

export default memo(ContentRowTemplate);
