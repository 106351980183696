import { Media, MediaOverlay, Sticker } from '@dce-front/dive';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { DIMENSIONS, type ImageSize } from '@dce-front/onewebapp-dive-utils';
import type { Ratio } from '@dce-front/onewebapp-utils';
import { TitleDisplayMode } from '@dce-front/sdk-hodor';
import classNames from 'classnames/bind';
import { type CSSProperties, type JSX } from 'react';
import { StrateMode } from '../../../../constants/strates';
import {
  getChannelCurrentLiveContent,
  getContentAriaLabel,
} from '../../../../helpers/contents/contents-helper';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import { STICKER_LABEL_LIVE } from '../../../../helpers/stickers/stickers-helper';
import { blackListedChannelsSelector } from '../../../../store/slices/application-selectors';
import type { ContentRowLiveData } from '../../../../templates/ContentRowLive/data/formatter';
import Linker from '../../../Linker/Linker';
import DiveProgressBar from '../../../ProgressBar/DiveProgressBar';
import StartOverButton from '../../../StartOverButton/StartOverButton';
import ThumborMediaImage from '../../../ThumborMediaImage/ThumborMediaImage';
import styles from '../ContentRowTemplateItem.css';
import { HeadlineLiveTv } from './HeadlineLiveTv';

const cx = classNames.bind(styles);

export type ContentRowTemplateItemLiveTvProps = {
  channel: ContentRowLiveData['channels'][0];
  isHeadline?: boolean;
  imageSize?: ImageSize;
  ratio: Ratio;
  titleDisplayMode?: TitleDisplayMode;
  style?: CSSProperties;
  index?: number;
  trackingContext?: ApiV2Context;
};

export function ContentRowTemplateItemLiveTv({
  channel,
  titleDisplayMode = TitleDisplayMode.All,
  isHeadline = false,
  ratio,
  imageSize = 'normal',
  style,
  index,
  trackingContext,
}: ContentRowTemplateItemLiveTvProps): JSX.Element | null {
  const blackListedChannels = useInvariantSelector(blackListedChannelsSelector);
  const liveContent = getChannelCurrentLiveContent(channel, trackingContext);

  // Check if channel is banned or content is not on air to delete obsolete card
  if (
    !liveContent ||
    (channel.epgID && blackListedChannels?.includes(channel.epgID))
  ) {
    return null;
  }

  const {
    current: { URLImage, startTime, endTime, altText },
    URLLogoChannel,
    altLogoChannel,
  } = liveContent;

  const isTitleVisible = titleDisplayMode !== TitleDisplayMode.None;
  const title = isTitleVisible ? liveContent.current.title : '';
  const ariaLabel = getContentAriaLabel(liveContent.current, {
    sticker: STICKER_LABEL_LIVE,
  });
  const subtitle =
    isTitleVisible && titleDisplayMode !== TitleDisplayMode.TitleOnly
      ? liveContent.current.subtitle
      : '';
  const image = {
    default: URLImage,
  };

  const onGoing = startTime ? { startTime, endTime } : undefined;

  const dimensions = (DIMENSIONS as any)[ratio][imageSize];

  const mediaCard = (
    <Media
      aspectRatio={ratio}
      aria-label={liveContent.current.title}
      image={
        URLImage ? (
          <ThumborMediaImage
            dimensions={dimensions}
            url={image.default}
            alt={altText}
          />
        ) : undefined
      }
      overlay={
        <MediaOverlay
          title={title}
          subtitle={subtitle}
          // Live sticker override
          stickerTop={
            <Sticker
              variant="emphasis"
              labelStyle="semibold"
              label={STICKER_LABEL_LIVE}
            />
          }
          actionableElementBottom={
            <StartOverButton
              context={liveContent.current.onClick?.trackingContext}
              channel={channel}
              title={liveContent?.current.title}
            />
          }
          logo={
            URLLogoChannel ? (
              <ThumborMediaImage
                dimensions={DIMENSIONS.LOGO_CHANNEL.large}
                url={URLLogoChannel}
                alt={altLogoChannel}
              />
            ) : undefined
          }
          progressBar={
            onGoing ? (
              <DiveProgressBar startTime={startTime} endTime={endTime} isLive />
            ) : undefined
          }
          data-tv-focusable={true}
        />
      }
    />
  );

  const itemClassnames = cx('contentRowTemplateItem', {
    'contentRowTemplateItem--logoTyped':
      titleDisplayMode === TitleDisplayMode.LogoTyped,
    'contentRowTemplateItem--liveTv': onGoing && $_BUILD_RENDERMODE_CSR,
  });

  const className = isHeadline
    ? styles.contentRowTemplateItem__fullWidth
    : itemClassnames;

  return (
    <li className={className} style={style} data-index={index}>
      <Linker
        className={styles.contentRowTemplateItem__itemLink}
        data={{
          mainOnClick: liveContent.current.onClick,
          ...liveContent,
        }}
        title={title}
        aria-label={ariaLabel}
        objKey={StrateMode.LiveTv}
      >
        {isHeadline && !$_BUILD_RENDERMODE_CSR ? (
          <HeadlineLiveTv
            title={title}
            subtitle={subtitle}
            summary={liveContent.current.summary}
          >
            {mediaCard}
          </HeadlineLiveTv>
        ) : (
          mediaCard
        )}
      </Linker>
    </li>
  );
}
